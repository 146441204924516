/**
 * 菜单路由 (根据菜单 生成路由)
 */
export function menuToRoute(menu)
{
    const rs_menu = [];
    
    menu.forEach(route_v => {
        
        let item;
        
        if (route_v.children && route_v.children.length)
        {
            const child = [];
            
            menuToRoute(route_v.children).map((route_b) => {
                child.push(route_b);
            })
            
            if (route_v.route_path)
            {
                item = {
                    meta: {true_id:route_v.id},
                    // name: route_v.name_id,
                    name: route_v.id,
                    path: route_v.route_path,
                    component : () => import('@/view/pages'+ route_v.view +''),
                    children:child,
                };
            } else {
                item = {
                    meta: {true_id:route_v.id},
                    // name: route_v.name_id,
                    name: route_v.id,
                    path: route_v.route_path,
                    // component : () => import('@/view/pages'+ route_v.view +''),
                    children:child,
                };
            }
            
            
        } else {
            
            item = {
                meta: {true_id:route_v.id},
                // name: route_v.name_id,
                name: route_v.id,
                path: route_v.route_path,
                component : () => import('@/view/pages'+ route_v.view +''),
            };
        }
        rs_menu.push(item);
    })
    
    return rs_menu;
}

/**
 * 递归获取 所有id
 */
export function getTreeAllId(arr)
{
        const id_arr = [];
        
        arr.forEach(v => {
            id_arr.push(v.id);
            
            if (v.childlist && v.childlist.length)
            {
                const id_arr_child = getTreeAllId(v.childlist);
                id_arr.push(...id_arr_child);
            }
        });
        
        return id_arr;
}

/**
 * 根据 pid  获取 name
 */
export function getTreeNameByPid(arr, pid) {
    let rs_arr = [];
    
    if (pid == 0 || !pid) return rs_arr;
    
    arr.forEach(v => {
        if (v.id == pid) {
            rs_arr.push(v.name);
        } else if (v.childlist && v.childlist.length) {
            const rs = getTreeNameByPid(v.childlist, pid);
            if (rs.length) rs_arr = rs;
        }
    });
    
    return rs_arr;
}


export function getItem(key)
{
    const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';
    
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
}

export function setItem(key, value)
{
    const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
    
    
    return localStorage.setItem(key, stringify);
}

export function removeItem(key)
{
    localStorage.removeItem(key);
}


export function strtotime(dateString)
{
    const date = new Date(dateString);
    return date.getTime()/1000;
}
/*timestamp 秒 不是毫秒*/
export function date(timestamp)
{
    if (timestamp)
    {
        timestamp *= 1000;
    } else {
        timestamp = new Date().getTime();
    }
    
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 根据秒数 返回参数结果
 *
 * second 秒数
 * type Y-m-d H:i:s
 */
export function getDateBySecond(second,type)
{
    if (type == 'his')
    {
        const h = Math.floor(second / 3600);
        const i = Math.floor((second % 3600) / 60);
        const s = second % 60;
        
        return {
            h:h,
            i:i,
            s:s,
        }
    }
}




/*站点 site_lang  用于网站管理  api 参数*/
export function setItemSiteLang(value)
{
    return setItem('site_lang',value);
}

export function getItemSiteLang()
{
    console.log('2132144234');
    
    return getItem('site_lang');
}


/*滚动到 form rules 错误位置*/
export function scrollRulesErrorPlace(v=0)
{
    /*滚动到报错位置*/
    const errorElement = document.querySelector('.ant-form-item-explain-error');
    
    if (errorElement) errorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });

    /*触发第二次 用于解决点击 第一次点击 className 未加载出来 未实现滚动效果*/
    if (v === 0) setTimeout(() => {scrollRulesErrorPlace(1)}, 0);
}


/*反选*/
/*a={id:3}
b=[{id:1},{id:2}]
判断a是否在b中 在删除 不在添加*/
export function reverseSelect(a, b)
{
    const itemIndex = b.findIndex(item => item.id === a.id);

    if (itemIndex !== -1)
    {
        b.splice(itemIndex, 1);
    } else {
        b.push(a);
    }
}


/*
import storage from '@@/utils/storage'

/!**
 * 获取当前访问应用类型
 *!/
export function getAppType() {
    const path = location.pathname.split('/').filter((val) => { return val })

    if (!path.length) {
        return 'admin'
    } else {
        const app = path[0]
        return app == 'decorate' ? 'site' : app
    }
}


/!**
 * 获取token
 * @returns
 *!/
export function getToken(): null | string {
    return storage.get('token')
}

/!**
 * 设置token
 * @param token
 * @returns
 *!/
export function setToken(token: string): void {
    storage.set({ key: 'token', data: token })
}

/!**
 * 移除token
 * @returns
 *!/
export function removeToken(): void {
    storage.remove('token')
}

/!**
 * 防抖函数
 * @param fn
 * @param delay
 * @returns
 *!/
export function debounce(fn: (args?: any) => any, delay: number = 300) {
    let timer: null | number = null
    return function (...args) {
        if (timer != null) {
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(() => {
            fn.call(this, ...args)
        }, delay);
    }
}

/!**
 * 判断是否是url
 * @param str
 * @returns
 *!/
export function isUrl(str: string): boolean {
    return str.indexOf('http://') != -1 || str.indexOf('https://') != -1
}

/!**
 * 图片输出
 * @param path
 * @returns
 *!/
export function img(path: string): string {
    // @ts-ignore  写这个注释就不会报错 import.meta.env
    return isUrl(path) ? path : `${import.meta.env.VITE_IMG_DOMAIN || location.origin}/${path}`
}

/!**
 * 获取字符串字节长度
 * @param str
 * @returns
 *!/
export function strByteLength(str: string = ''): number {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
            len += 2;
        } else {
            len++;
        }
    }
    return len;
}

/!**
 * url 转 route
 * @param url 
 *!/
export function urlToRouteRaw(url: string) {
    const query = {}
	const [path, param] = url.split('?')

	param && param.split('&').forEach((str : string) => {
		let [name, value] = str.split('=')
		query[name] = value
	})

	return { path, query }
}*/
