import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';

import chartContent from './modules/chartContent/actionCreator';

import firebase from './modules/authentication/firebase/actionCreator';
import auth from './modules/authentication/axios/actionCreator';

export default createStore({
  modules: {
    themeLayout,
    chartContent,
    auth,
    firebase,
  },
});
