import mutations from './mutations';

const state = () => ({
    data: false,
    rtlData: false,
    topMenu: false,
    loading: false,
    rtlLoading: false,
    menuLoading: false,
    error: null,
    
    /*折叠*/
    collapsed: false,
    
    /*用于 限制 多个组件 窗口大小改变 触发(相同的元素（左侧menu） 最好只由一个方法去触发)*/
    window_resize_menu_page:[],
    window_resize_menu_width:[],
    
});

const actions = {
    async changeLayoutMode({ commit }, value) {
        try {
            commit('changeLayoutBegin');
            setTimeout(() => commit('changeLayoutSuccess', value), 10);
        } catch (err) {
            commit('changeLayoutErr', err);
        }
    },
    
    async changeRtlMode({ commit }, value) {
        try {
            commit('changeRtlBegin');
            setTimeout(() => commit('changeRtlSuccess', value), 10);
        } catch (err) {
            commit('changeRtlErr', err);
        }
    },
    
    async changeMenuMode({ commit }, value) {
        try {
            commit('changeMenuBegin');
            setTimeout(() => commit('changeMenuSuccess', value), 10);
        } catch (err) {
            commit('changeMenuErr', err);
        }
    },
    
    
    /*左侧menu 大小 */
    async menuSizeFunc({ dispatch }, width)
    {
        /*小于 width 触发*/
        if (window.innerWidth < width && !this.state.themeLayout.collapsed) dispatch('menuSize',true);
        
        /*大于 width 触发*/
        if (window.innerWidth > width && !!this.state.themeLayout.collapsed) dispatch('menuSize',false);
    },
    
    /*左侧menu 大小  collapsed*/
    async menuSize({ commit }, value)
    {
        commit('menuSize', value);
    },
    
    /*记录  触发方法的 页面*/
    async window_resize_menu_func({ commit }, e)
    {
        commit('window_resize_menu_func', e);
    },
    
    /*记录  触发方法的 页面*/
    async window_resize_menu_func_r({ commit }, e)
    {
        commit('window_resize_menu_func_r', e);
    },
    
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
