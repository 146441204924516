import request from '@/utils/request'

/**
 * 登录
 * @param params
 */
export function login(params)
{
    return request.post(`/login`, params)
}

/**
 * 登录
 * @param params
 */
export function logout(params)
{
    return request.put(`/auth/logout`, params)
}

/**
 * menu
 * @param params
 */
export function getMenu(params)
{
    return request.get(`/auth/menu`, params)
}

