export default {
    changeLayoutBegin(state) {
        state.loading = true;
    },
    
    changeLayoutSuccess(state, data) {
        state.loading = false;
        state.data = data;
    },
    
    changeLayoutErr(state, err) {
        state.loading = false;
        state.error = err;
    },
    
    changeRtlBegin(state) {
        state.loading = true;
    },
    
    changeRtlSuccess(state, data) {
        state.loading = false;
        state.rtlData = data;
    },
    
    changeRtlErr(state, err) {
        state.loading = false;
        state.error = err;
    },
    
    changeMenuBegin(state) {
        state.loading = true;
    },
    
    changeMenuSuccess(state, data) {
        state.loading = false;
        state.topMenu = data;
    },
    
    changeMenuErr(state, err) {
        state.loading = false;
        state.error = err;
    },
    
    /*折叠菜单*/
    menuSize(state, value) {
        if (typeof value ===  'boolean')
        {
            state.collapsed = value;
        } else {
            state.collapsed = !state.collapsed;
        }
    },
    
    window_resize_menu_func(state, e) {
        state.window_resize_menu_page.push(e.page);
        
        state.window_resize_menu_width.push(e.width)
    },
    
    window_resize_menu_func_r(state, e) {
        state.window_resize_menu_page = state.window_resize_menu_page.filter(item => item !== e.page);
        
        state.window_resize_menu_width = state.window_resize_menu_width.filter(item => item !== e.width);
    },
};
