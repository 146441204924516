import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';

import './static/css/style.css';
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';

import '@/assets/common/css/global.css'
import i18n from "@/lang";
app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');
