import { createI18n } from "vue-i18n";
// import {getItem} from '@/utility/localStorageControl.js';
import {getItem} from '@/utils/common.js';

import cn from '@/lang/cn.json';
import en from '@/lang/en.json';

/*创建实例*/
const i18n = createI18n({
    locale: getItem('lang') || 'cn',
    messages: {
        cn,
        en
    }
});


const lang = (message) => {
    
    return i18n.global.t(message);
    
}

export { i18n, lang };

export default i18n;