const routes = [
  {
    path: "",
    name: "1",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/view/dashboard/Dashboard.vue"
      ),
    children: [
      {
        path: "",
        name: "1",
        // component: () => import(/* webpackChunkName: "Home" */ '@/view/dashboard/Index.vue'),
        component: () =>
          import(
            /* webpackChunkName: "Home" */ "@/view/pages/dashboard/Index.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "not-found",
        component: () => import("@/view/pages/404.vue"),
      },
    ],
  },
  {
    path: "userManage",
    name: "userManage",
    component: () => import("@/components/Info/userManage.vue"),
  },
  {
    path: "custom",
    name: "custom",
    component: () => import("@/components/Info/custom.vue"),
  },
  {
    path: "departmentManage",
    name: "departmentManage",
    component: () => import("@/components/Info/departmentManage.vue"),
  },
  {
    path: "Activity",
    name: "Activity",
    component: () => import("@/components/Info/Activity.vue"),
  },
  {
    path: "Settings",
    name: "Settings",
    component: () => import("@/components/Info/Settings.vue"),
  },
  {
    path: "Help",
    name: "Help",
    component: () => import("@/components/Info/Help.vue"),
  },


];

export default routes;
